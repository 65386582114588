<template>
  <div class="aboutus">
    <el-backtop :visibility-height="20"> </el-backtop>
    <div class="website_header">
      <img
        src="@/assets/logo2.png"
        :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"
        alt=""
      />
      <h1 :style="{ fontSize: pageWidth < 700 ? '32px' : '64px' }">关于我们</h1>
    </div>

    <div class="about_content">
      <div v-for="(item, i) in aboutList" :key="i">
        <h3 :style="{ fontSize: pageWidth < 700 ? '16px' : '20px' }">
          {{ item.time }}
        </h3>
        <p
          :style="{
            fontSize: pageWidth < 700 ? '15px' : '18px',
            marginLeft: pageWidth < 700 ? '0' : '24px',
          }"
        >
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
export default {
  data() {
    return {
      aboutList: [
        { time: "2023/06", title: "平台CPLE质押量突破1800万" },
        {
          time: "2023/04",
          title: "进入东南亚市场，分别在香港与马来西亚成立企业",
        },
        { time: "2022/10", title: "趣出行APP升级为国际版，支持5种语言" },
        { time: "2022/08", title: "趣宝盒售出量突破2000台" },
        { time: "2021/11", title: "开启行程上链，推出趣宝盒" },
        {
          time: "2021/09",
          title: "申请国高新企业，同年12月被评选为国家高新企业",
        },
        { time: "2020/06", title: "平台开启线路竞拍" },
        { time: "2020/06", title: "平台用户人数突破600万人" },
        {
          time: "2019/10",
          title:
            "北京趣出行科技公司成为第二批在国家网信办备案的区块链企业，同月并获得了中关村企业研发费用支持",
        },
        { time: "2018/12", title: "累计用户100万人" },
        { time: "2018/10", title: "进行链改，引入区块链通证经济" },
        {
          time: "2018/5",
          title:
            "北京趣出行科技公司被评为海淀区高新技术企业，同时获得海淀区政府的专项资金奖励",
        },
        { time: "2017", title: "公司试运营+内测，当年用户突破5万人" },
        { time: "2016/10", title: "北京趣出行科技公司项目立项" },
      ],
      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },
};
</script>

<style></style>
